// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oygeeQWL8Ct2K7Oj1XhA{text-decoration:underline;cursor:pointer}.HprYyB7D804I9ExGD_Z6,.HoY_N7yp18KfEz7ILkIf{position:relative;padding-left:20px;color:#50a40e}.HprYyB7D804I9ExGD_Z6::before,.HoY_N7yp18KfEz7ILkIf::before{display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Check-green.svg")}.HprYyB7D804I9ExGD_Z6::before,.HoY_N7yp18KfEz7ILkIf::before{position:absolute;top:50%;left:0;transform:translateY(-50%);width:16px;height:16px;background-size:contain}.HoY_N7yp18KfEz7ILkIf{color:#c0453a}.HoY_N7yp18KfEz7ILkIf::before{display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Close-fire.svg")}`, ""]);
// Exports
export var inventoryIndicator = `oygeeQWL8Ct2K7Oj1XhA`;
export var check = `HprYyB7D804I9ExGD_Z6`;
export var close = `HoY_N7yp18KfEz7ILkIf`;
export default ___CSS_LOADER_EXPORT___;

import * as styles from "./app.module.scss";
import classnames from "classnames";
import { useState } from "react";
import { popup, ButtonResult, ButtonType, PopupSize, useCartChange, getAvailabilityDisplay } from "ui";
import { Inventories } from "ui/src/components/Inventories/Inventories";


function App() {
    const translations = (window as any).app.preloadState.translation;
    const part = (window as any).app.preloadState.inventoryLabel;

    const [hasStockAvailability, setHasStockAvailability] = useState(part.isAvailableInBranch);

    useCartChange(() => {
        (async () => {
            const stockAvailability = await getAvailabilityDisplay(part.code);
            setHasStockAvailability(stockAvailability.hasEnoughInStock);
        })();
    })

    const showInventoryPopup = () => {
        popup(
            translations["inventoryPopup.stockStatusAtFacility"],
            <Inventories itemCode={part.code} itemDisplayName={part.displayName} />,
            [
                { label: translations["inventoryPopup.cancel"], result: ButtonResult.Cancel, type: ButtonType.Primary }
            ],
            PopupSize.Large
        );
    }

    return part.branchDisplayName ?
            <span
                onClick={showInventoryPopup}
                className={classnames(styles.inventoryIndicator, {
                    [styles.check]: hasStockAvailability,
                    [styles.close]: !hasStockAvailability,
                })}
            >
                {part.branchDisplayName}
            </span>
            :
            <span
                onClick={showInventoryPopup}
                className={classnames(styles.inventoryIndicator)}
            >
                {translations["inventories.selectBranch"]}
            </span>
}




export default App;
